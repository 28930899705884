import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import { withWidth } from '@material-ui/core';

import { useAuth0 } from '@auth0/auth0-react';
import { getUserEmail } from 'apputil/user';
import { forceWait } from 'apputil/retry-promise';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons';
import { faSmileBeam, faDirections } from '@fortawesome/pro-light-svg-icons';

import { REGISTRATION_START } from 'constants/navigation';

import useStyles from './styles';

const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

const Button = loadable(() => import('@material-ui/core/Button'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const Grid = loadable(() => import('@material-ui/core/Grid'));
const Typography = loadable(() => import('@material-ui/core/Typography'));

const LoginHomeView = ({ subscription = '', focus = '', width = '' }) => {
  const styles = useStyles();

  const [working, setWorking] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (redirecting) {
      setWorking(false);
      setRedirecting(false);
    }

    return () => {
      setWorking(false);
      setRedirecting(true);
    };
  }, [redirecting]);

  return (
      <div className={styles.content}>
      <Grid spacing={2}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Paper className={styles.paper} elevation={4}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              >
            <Grid item xl={8} md={10} sm={10} xs={12}>
            <Typography
              variant="h4"
              component="h4"
              align="center"
              className={styles.headerGreen}>
                Welcome back
                <FontAwesomeIcon icon={faSmileBeam} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="p"
                  align="right"
                  className={styles.headerSubText}>
                  Great to see you again
                </Typography>
              </Typography>
            </Grid>
             <Grid item xs={10} sm={10} md={8} xl={6} className={styles.noMargin}>
             {working ? (
               <Typography
                 variant="h4"
                 component="p"
                 align="center"
                 className={styles.redirectText}>
                  Redirecting you now...
                  <FontAwesomeIcon icon={faDirections} size="lg" className={styles.redirectIcon}/>
               </Typography>
             ) : (
               <Button
                 variant="contained"
                 color="primary"
                 fullWidth
                 className={styles.greenformbutton}
                 disabled={working}
                 aria-label="start registration"
                 onClick={() => {
                   setWorking(true);
                   setTimeout(() => {
                     const email = getUserEmail() || '';
                     if (emailRegex.test(email)) {
                       loginWithRedirect({
                         prompt: 'select_account',
                         login_hint: email,
                         appState: {},
                       })
                         .then(() => forceWait({ time: 1000 }))
                         .then(() => setRedirecting(true));
                     } else {
                       loginWithRedirect({
                         prompt: 'select_account',
                         appState: {},
                       })
                         .then(() => forceWait({ time: 1000 }))
                         .then(() => setRedirecting(true));
                     }
                   }, 100);
                 }}
                 >
                {width === 'xs' ? 'Login' : 'Login to Total Loyalty'}
                <FontAwesomeIcon icon={faSignIn} className={styles.submitIcon}/>
              </Button>
             )}
             </Grid>
             <Grid item xs={12} sm={10} className={styles.divider}/>
             {!working && (
               <Grid item xs={10} sm={10} md={8}>
                 <Link to={REGISTRATION_START}>
                   <Typography
                     variant="subtitle1"
                     component="p"
                     color="primary"
                     align="right"
                     className={styles.loginLink}>
                     Create an account
                   </Typography>
                 </Link>
               </Grid>
             )}
            </Grid>
          </Paper>
        </Grid>
       </Grid>
      </div>
  );
};

LoginHomeView.propTypes = {
  subscription: string,
  focus: string,
  width: string,
};

export default withWidth()(LoginHomeView);
