import { LoggableError } from 'logger';

export const retryPromise = ({
  action, count = 0, amount = 5, logger,
}) => new Promise((resolve, reject) => {
  logger.info({ caller: 'retryPromise:run', count });
  try {
    action();
    resolve();
  } catch (err) {
    logger.error(err);

    if (count === amount) {
      const errToThrow = new LoggableError({
        data: { err, count },
        caller: 'retryPromise:too_many_calls',
      });
      reject(errToThrow);
    } else {
      setTimeout(() => retryPromise({
        action, count: count + 1, amount, logger,
      }), 100);
    }
  }
});

export const forceWait = ({ time = 1000 } = {}) => new Promise(
  (resolve) => setTimeout(() => resolve(), time),
);
