import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  content: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },

  paper: theme.mixins.gutters({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  }),
  bodyHeader: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  body: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  loginLink: {
    marginBottom: theme.spacing(2),
  },
  headerIcon: {
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  headerGreen: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginRight: 'auto !important',
    marginLeft: 'auto !important',
    width: '50%',
    borderBottomWidth: theme.spacing(0.2),
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.secondary.main,
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  headerSubText: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  redirectText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  redirectIcon: {
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
});
